/* 
    Document   : ie8
    Created on : 28 janv. 2014, 11:10:15
    Author     : FOURNET
    Description:
        Purpose of the stylesheet follows.
*/

#content .offres .cycle-slideshow div span {
    background:#FFFFFF;
}
#banner .slide img{
    max-width: 1000px;
}

