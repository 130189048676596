.oembedall-closehide {
  background-color: #aaa;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 3px; }

.oembedall-container {
  margin-top: 5px;
  text-align: left; }

.oembedall-githubrepos {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font: 13.34px/1.4 helvetica,arial,freesans,clean,sans-serif;
  list-style-type: none;
  margin: 0 0 10px;
  padding: 8px 10px 0;
  width: 452px; }
  .oembedall-githubrepos .oembedall-body {
    background: -moz-linear-gradient(center top, #fafafa, #efefef) repeat scroll 0 0 transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(#fafafa), to(#efefef));
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #eee;
    margin-left: -10px;
    margin-top: 8px;
    padding: 5px 10px;
    width: 100%; }
  .oembedall-githubrepos h3 {
    font-size: 14px;
    margin: 0;
    padding-left: 18px;
    white-space: nowrap; }
  .oembedall-githubrepos .oembedall-description {
    color: #444;
    font-size: 12px;
    margin: 0 0 3px; }
  .oembedall-githubrepos .oembedall-updated-at {
    color: #888;
    font-size: 11px;
    margin: 0; }
  .oembedall-githubrepos .oembedall-repo-stats {
    border: medium none;
    float: right;
    font-size: 11px;
    font-weight: bold;
    margin: 0;
    padding-left: 15px;
    position: relative;
    z-index: 5; }
    .oembedall-githubrepos .oembedall-repo-stats li {
      border: medium none;
      color: #666;
      display: inline-block;
      list-style-type: none;
      margin: 0 !important; }
      .oembedall-githubrepos .oembedall-repo-stats li a {
        background-color: transparent;
        background-position: 5px -2px;
        background-repeat: no-repeat;
        border: medium none;
        border-left: 1px solid #ddd;
        color: #666 !important;
        display: inline-block;
        height: 21px;
        line-height: 21px;
        padding: 0 5px 0 23px; }
      .oembedall-githubrepos .oembedall-repo-stats li:hover {
        background: none no-repeat scroll 5px -27px #4183c4;
        color: #fff !important;
        text-decoration: none; }
    .oembedall-githubrepos .oembedall-repo-stats li:first-child a {
      border-left: medium none;
      margin-right: -3px; }
      .oembedall-githubrepos .oembedall-repo-stats li:first-child a:hover {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px; }
    .oembedall-githubrepos .oembedall-repo-stats li:last-child a:hover {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }

.oembedall-lanyard {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-delay: 0s;
  -webkit-transition-duration: .4s;
  -webkit-transition-property: width;
  -webkit-transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  color: #112644;
  display: block;
  float: left;
  font-family: 'Trebuchet MS', Trebuchet, sans-serif;
  font-size: 16px;
  height: 253px;
  line-height: 19px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-width: none;
  min-height: 0;
  outline-color: #112644;
  outline-style: none;
  outline-width: 0;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: relative;
  text-align: left;
  vertical-align: baseline;
  width: 804px; }
  .oembedall-lanyard .tagline {
    font-size: 1.5em; }
  .oembedall-lanyard .wrapper {
    clear: both;
    overflow: hidden; }
  .oembedall-lanyard .split {
    display: inline;
    float: left; }
  .oembedall-lanyard .prominent-place .flag:link,
  .oembedall-lanyard .prominent-place .flag:visited,
  .oembedall-lanyard .prominent-place .flag:hover,
  .oembedall-lanyard .prominent-place .flag:focus,
  .oembedall-lanyard .prominent-place .flag:active {
    display: block;
    float: left;
    height: 48px;
    margin-right: 10px;
    position: relative;
    top: -5px;
    width: 48px; }
  .oembedall-lanyard .place-context {
    font-size: .889em; }
  .oembedall-lanyard .prominent-place {
    font-size: 1.125em;
    font-weight: normal;
    line-height: 1.1em; }
    .oembedall-lanyard .prominent-place .sub-place {
      display: block; }
  .oembedall-lanyard .main-date {
    color: #8cb4e0;
    font-weight: bold;
    line-height: 1.1; }
  .oembedall-lanyard .first {
    margin: 0 0 0 2.857%;
    margin-left: 0;
    width: 48.57%; }

.oembedall-ljuser {
  font-weight: bold; }
  .oembedall-ljuser img {
    border: 0;
    padding-right: 1px;
    vertical-align: bottom; }

.oembedall-stoqembed {
  background: none repeat scroll 0 0 #fff;
  border-bottom: 1px dotted #999;
  color: #000;
  float: left;
  font-family: Arial,Liberation Sans,DejaVu Sans,sans-serif;
  font-size: 80%;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 730px; }
  .oembedall-stoqembed a {
    color: #07c;
    margin: 0;
    padding: 0;
    text-decoration: none; }
    .oembedall-stoqembed a:hover {
      text-decoration: underline; }
    .oembedall-stoqembed a:visited {
      color: #4a6b82; }
  .oembedall-stoqembed h3 {
    font-family: Trebuchet MS,Liberation Sans,DejaVu Sans,sans-serif;
    font-size: 130%;
    font-weight: bold;
    margin: 0;
    padding: 0; }
  .oembedall-stoqembed .oembedall-reputation-score {
    color: #444;
    font-size: 120%;
    font-weight: bold;
    margin-right: 2px; }
  .oembedall-stoqembed .oembedall-user-info {
    height: 35px;
    width: 185px; }
    .oembedall-stoqembed .oembedall-user-info .oembedall-user-gravatar32 {
      float: left;
      height: 32px;
      width: 32px; }
    .oembedall-stoqembed .oembedall-user-info .oembedall-user-details {
      float: left;
      margin-left: 5px;
      overflow: hidden;
      white-space: nowrap;
      width: 145px; }
  .oembedall-stoqembed .oembedall-question-hyperlink {
    font-weight: bold; }
  .oembedall-stoqembed .oembedall-stats {
    background: none repeat scroll 0 0 #eee;
    margin: 0 0 0 7px;
    padding: 4px 7px 6px;
    width: 58px; }
  .oembedall-stoqembed .oembedall-statscontainer {
    float: left;
    margin-right: 8px;
    width: 86px; }
  .oembedall-stoqembed .oembedall-votes {
    color: #555;
    padding: 0 0 7px;
    text-align: center; }
  .oembedall-stoqembed .oembedall-vote-count-post {
    color: #808185;
    display: block;
    font-size: 240%;
    font-weight: bold; }
  .oembedall-stoqembed .oembedall-views {
    color: #999;
    padding-top: 4px;
    text-align: center; }
  .oembedall-stoqembed .oembedall-status {
    background: none repeat scroll 0 0 #75845c;
    color: #fff;
    margin-top: -3px;
    padding: 4px 0;
    text-align: center; }
    .oembedall-stoqembed .oembedall-status strong {
      color: #fff;
      display: block;
      font-size: 140%; }
  .oembedall-stoqembed .oembedall-summary {
    float: left;
    width: 635px; }
  .oembedall-stoqembed .oembedall-excerpt {
    line-height: 1.2;
    margin: 0;
    padding: 0 0 5px; }
  .oembedall-stoqembed .oembedall-tags {
    float: left;
    line-height: 18px; }
    .oembedall-stoqembed .oembedall-tags a:hover {
      text-decoration: none; }
  .oembedall-stoqembed .oembedall-post-tag {
    background-color: #e0eaf1;
    border-bottom: 1px solid #3e6d8e;
    border-right: 1px solid #7f9fb6;
    color: #3e6d8e;
    font-size: 90%;
    line-height: 2.4;
    margin: 2px 2px 2px 0;
    padding: 3px 4px;
    text-decoration: none;
    white-space: nowrap; }
    .oembedall-stoqembed .oembedall-post-tag:hover {
      background-color: #3e6d8e;
      border-bottom: 1px solid #37607d;
      border-right: 1px solid #37607d;
      color: #e0eaf1; }
  .oembedall-stoqembed .oembedall-fr {
    float: right; }
  .oembedall-stoqembed .oembedall-statsarrow {
    background-image: url("//cdn.sstatic.net/stackoverflow/img/sprites.png?v=3");
    background-position: 0 -435px;
    background-repeat: no-repeat;
    float: right;
    height: 13px;
    margin-top: 12px;
    overflow: hidden;
    width: 7px; }

/*# sourceMappingURL=jquery.oembed.css.map */
