@charset "utf-8";
/* CSS Document */

@charset "utf-8";
/* CSS Document */

@media screen and (max-width: 980px){
	header#entete .pub{display:none;}
	header#entete .baseline{ font-size: 20px; }
}

@media screen and (max-width: 767px){
	.cols2 { -webkit-column-count: 1; /* Chrome, Safari, Opera */ -moz-column-count: 1; /* Firefox */ column-count: 1; -webkit-column-gap: 40px; /* Chrome, Safari, Opera */ -moz-column-gap: 40px; /* Firefox */ column-gap: 40px; }
	.cols3 { -webkit-column-count: 1; /* Chrome, Safari, Opera */ -moz-column-count: 1; /* Firefox */ column-count: 1; -webkit-column-gap: 40px; /* Chrome, Safari, Opera */ -moz-column-gap: 40px; /* Firefox */ column-gap: 40px; }

		#menu > ul > li.dropdown > ul { top: 44px; }
}

@media screen and (min-width: 480px) and (max-width: 767px){
	#menu > ul {display:block;}
	#menu > ul > li{display:block; text-align:center; float:left; width:50%; border:none;}
	#menu > ul > li table {margin:auto;}
	#menu > ul > li:nth-child(2n+1){clear:both; border-right:2px solid #ecf0f1;}
	#menu > ul > li.reseaux span{display:inline;}
	#menu > ul > li.newsletter, #menu ul li.reseaux{cleat:both; width:100%;}
	#menu > ul > li.newsletter i{position:relative; top:0; left:0;}
	#menu > ul > li.newsletter strong{display:inline;}
}

@media screen and (max-width: 640px){

	#banner .infos .details .title { font-size: 18px;}
	#banner .infos .details .presentation { font-size: 12px;}

	#content .badge-title strong { font-size: 16px;}
	#content .badge-title span { font-size: 12px;}

	#content .slideshow .cycle-slideshow div span { padding:5px; font-size: 10px; }
	#content .slideshow .cycle-slideshow div span .titre { font-size: 14px; }

	header#entete .logo{position:relative; left:0; margin:auto;}
	header#entete .baseline{display: none;}
	#menu >ul {display:block;}
	#menu >ul > li{display:block; text-align:center; float:left; width:50%; border:none;}
	#menu >ul > li table {margin:auto;}
	#menu >ul > li:nth-child(2n+1){clear:both; border-right:2px solid #ecf0f1;}
	#menu >ul > li.reseaux span{display:inline;}
	#menu >ul > li.newsletter, #menu ul li.reseaux{cleat:both; width:100%;}
	#menu >ul > li.newsletter i{position:relative; top:0; left:0;}
	#menu >ul > li.newsletter strong{display:inline;}


	#banner .infos{ top:auto; bottom:0; left:0; padding:0 0 0 15px; margin-top:0; font-size:12px;}
	#banner .infos .details:before{border:none;}
	#banner .infos .details{padding:10px 10px 12px 0;}
	#banner .infos .details h1{font-size:18px;}
	#banner .infos .details h1 i{font-size:8px;}
	#banner .infos .details .lieu{margin:0 0 5px 0;}
	#banner .infos .details .presentation{font-size:12px;}
	#banner .infos .prix strong{font-size:22px;}
	#banner .slide .commandes a{bottom:0; zoom:50%;}
	#banner .slide .selection{width:124px; zoom:70%; right:0;}
	#banner .slide .pictos{top:0; zoom:75%;}

	#banner .slide-pager { display: none; }

	#mosaic { display: flex; flex-flow: row wrap; justify-content: space-between; align-items: stretch; }
	#mosaic > * { width: 100%; }
	#mosaic > *:nth-child(4n+1) a > span:last-child:before { left: -10px; border-right:10px solid; border-right-color:inherit; }
	#mosaic > *:nth-child(4n+2) a > span:last-child:before { left: -10px; border-right:10px solid; border-right-color:inherit; }
	#mosaic > *:nth-child(4n+3) a > span:last-child:before { right: -10px; border-left:10px solid; border-left-color:inherit; }
	#mosaic > *:nth-child(4n+4) a > span:last-child:before { right: -10px; border-left:10px solid; border-left-color:inherit; }
	
	#content h2{zoom:80%;}
	#content h3{zoom:90%;}
	
	#content .left, #content .right{width:100%; float:none;}
	#content .reseaux h3{display:block; width:100%; margin-right:0;}
	#content .reseaux .pictos{display:block; padding-top:0;}
	
	#content .ventes .picture .table{zoom:65%;}
	
	#content .offres .cycle-prev{left:-20px;}
	#content .offres .cycle-next{right:-20px;}
	#content .offres .commandes a{zoom:70%;}

	#content .search .search-body .search-filter {
		float: none;
		height: auto;
		line-height: 1.5em;
		padding-bottom: 1em;
		overflow: hidden;
	}
	#content .search .search-body .search-filter dd {
		white-space: nowrap;
		float: left;
	}
	#content .search .search-body .search-mode {
		float: none;
		height: auto;
	}
	#content .search .search-header .search-title {
		font-size: 20px;
	}
	#content .search-results article {
		padding: 1em;
	}
	#content .search-results article .item-name {
		font-size: 16px;
		font-weight: normal;
	}
	#content .search-results article .item-location {
		font-size: 12px;
		float: left;
		line-height: 20px;
	}
	#content .search-results article .item-type {
		font-size: 10px;
		float: left;
		line-height: 25px;
		padding-left: 5px;
	}
	#content .search-results article .item-resume {
		clear: left;
		font-size: 12px;
		text-align: left;
		line-height: 1.2em;
		max-height: 4.8em;
		overflow: hidden;
	}
	#content .search-results article .item-more {
		padding: 5px;
		bottom: 10px;
	}
	#content .search-results article a:last-child {
		flex:1.5;
		padding-right: 0;
	}

	#content .main {float:none;  width: 100%;}
	#content .sidebar { display: none; }

	#content .tripadvisor-wrapper .cdsROWContainer { width: 100% !important; }
	#content .tripadvisor-wrapper .cdsROWContainer .cdsComponent { display:block !important; width:100%!important; text-align: center; padding: 5px !important; }
	footer#pied  .content{padding:15px;}
	footer#pied .content .bloc{float:none; width:100% !important; border-top:1px solid #777; margin:0; padding:15px 0 20px 0;}
	footer#pied .content .bloc:first-child{border:none;}
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
	#menu ul li a{padding-left:3px; padding-right: 3px;}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	#wrapper, footer#pied{zoom:80%;}
	footer#pied form{zoom:70%;}
	#banner .slide .pictos{top:0;}
	#banner .infos{bottom:0; left:0; margin-top:0;}
	#banner .infos .details:before{border:none;}
	#banner .slide .selection{width:124px; right:0;}
}