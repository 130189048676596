@charset 'utf-8';
/* CSS Document */

body { font-family: 'Lato', Arial, Helvetica, sans-serif; font-size: 14px; color: #777; background: #eee; }

body, table, tr, td, ul, li, ol, dl, dt, dd, h1, h2, h3, h4, h5, h6, p, form, figure, article, aside {margin: 0;  padding: 0; list-style: none; }

img { max-width: 100%; vertical-align: middle; border: 0; }
/* Google Map fix*/
.gm-style img, .gmnoscreen img, .gmnoprint img { max-width: none !important; }

a {text-decoration: underline;color: #777;  outline: none; }
a:hover { color: #28adf0; }

* {-moz-box-sizing: border-box; box-sizing: border-box;  }

.cols2 { -webkit-column-count: 2; /* Chrome, Safari, Opera */ -moz-column-count: 2; /* Firefox */ column-count: 2; -webkit-column-gap: 40px; /* Chrome, Safari, Opera */ -moz-column-gap: 40px; /* Firefox */ column-gap: 40px; }
.cols3 { -webkit-column-count: 3; /* Chrome, Safari, Opera */ -moz-column-count: 3; /* Firefox */ column-count: 3; -webkit-column-gap: 40px; /* Chrome, Safari, Opera */ -moz-column-gap: 40px; /* Firefox */ column-gap: 40px; }
.cols2 .nobreak, .cols3 .nobreak { overflow: hidden; /* Fix for firefox and IE 10-11  */ -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */ page-break-inside: avoid; /* Deprecated Firefox */ -moz-column-break-inside: avoid; /* Current Firefox */ column-break-inside: avoid; break-inside: avoid; /* IE 10+ */ break-inside: avoid-column; }

input, textarea, select {font-family: 'Lato', Arial, Helvetica, sans-serif; font-size: 12px; padding: 4px;color: #777;  border: 1px solid #dadada; background: #fff; }
a.button,
button.valid {text-decoration: none; font-family: 'Lato', Arial, Helvetica, sans-serif; font-size: 12px; font-weight: bold; margin: 0;padding: 4px 5px; text-align: center; color: #fff; border: 1px solid #f39c12;  background: #f39c12; }
a.button:hover,
button.valid:hover {cursor: pointer;border: 1px solid #28adf0;  background: #28adf0; }
a.button.btn-big,
button.btn-big { font-size: 16px; padding: 6px 10px; }

.clearfix:after {line-height: 0; display: block; visibility: hidden; clear: both; height: 0;  content: '.'; }

#wrapper {max-width: 1000px; margin: auto; background: #fff; }

header#entete { position: relative; z-index: 2; min-height: 178px; }
header#entete .logo {position: absolute; top: 15px; left: 26px;width: 147px; height: 143px; text-indent: -90000px;  background: url(../img/logo.png) no-repeat left top; }
header#entete .logo a {display: block; width: 147px; height: 143px; }
header#entete .baseline { position: absolute; top: 60px; left: 210px; margin: 0; padding: 0; font-size: 26px; }
header#entete .baseline p.title { font-size: 1em; font-style: normal; color: #333; }
header#entete .baseline p.title strong {font-weight: 900; color: #28acf0; }
header#entete .baseline p { font-size: 0.61em; font-style: italic; }
header#entete .social {font-size: 0; position: absolute; top: 0; right: 0; padding: 20px; }
header#entete .social i { font-size: 18px; line-height: 30px; width: 30px; height: 30px; margin-left: 10px;text-align: center; color: #fff; border-radius: 50%; }
header#entete .social a:hover i { background: #333; }

#menu {position: relative; z-index: 4;margin-bottom: 44px;  background: #ecf0f1; }
#menu > ul {position: relative; display: table; }
#menu > ul > li {font-size: 13px; font-weight: 700;  display: table-cell; height: 44px; vertical-align: middle; border-left: 2px solid #fff; }
#menu > ul > li a {display: block; padding: 10px 15px;text-decoration: none; text-transform: uppercase;  color: #fff; }
#menu > ul > li li a { padding: 5px 10px; }
#menu > ul > li > table > tr > td { vertical-align: middle; }
#menu > ul > li > table > tr > td:first-child { padding-right: 5px; }
#menu > ul > li > strong { font-size: 13px; text-transform: uppercase; color: #d35400; }

#menu > ul > li:first-child { border: none; }
#menu > ul > li.colored:nth-child(1), #menu > ul > li.colored:nth-child(1) > ul { background: #f39c12; }
#menu > ul > li.colored:nth-child(2), #menu > ul > li.colored:nth-child(2) > ul { background: #d35400; }
#menu > ul > li.colored:nth-child(3), #menu > ul > li.colored:nth-child(3) > ul { background: #c0392b; }
#menu > ul > li.colored:nth-child(4), #menu > ul > li.colored:nth-child(4) > ul { background: #3498db; }
#menu > ul > li.colored:nth-child(5), #menu > ul > li.colored:nth-child(5) > ul { background: #97d025; }
#menu > ul > li.colored:nth-child(6), #menu > ul > li.colored:nth-child(6) > ul { background: #c80087; }
#menu > ul > li.colored:hover { background: #253746; }

#menu > ul > li.dropdown { position: relative; }
#menu > ul > li.dropdown > ul { position: absolute; top: 52px; display: none;border-top: 2px solid #fff; background: inherit; z-index: 10;  }
#menu > ul > li.dropdown > ul > li { white-space: nowrap; }
#menu > ul > li.dropdown:hover > ul { display: block; }
#menu > ul > li.dropdown > ul > li:hover { background: #253746; }

#menu > ul > li.newsletter {font-size: 11px; padding: 3px 5px 3px 15px; color: #333; }
#menu > ul > li.newsletter a { color: #333; }
#menu > ul > li.newsletter:hover i, #menu > ul > li.newsletter:hover strong { color: #3498db !important; }
#menu > ul > li.newsletter i {font-size: 24px;  color: #d35400; }
#menu > ul > li.newsletter strong { display: block; color: #d35400; }
#menu > ul > li.newsletter:hover { background: none; }

#banner {font-size: 0;  position: relative; z-index: 3; clear: both; margin: -44px 0 20px; }
#banner .infos {font-size: 14px; position: absolute;  z-index: 10; z-index: 2; top: 50px; left: -20px; display: inline-block; max-width: 100%;margin-top: -15px; padding: 0 0 0 38px; color: #fff; background: #253746; }
#banner .infos .details { padding: 10px 50px 12px 0; }
#banner .infos .details:before {position: absolute; top: -20px; left: 0; width: 0;height: 0; content: '.'; text-indent: -8000px; border-width: 0 0 20px 20px; border-style: solid;  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #7d7f83; border-right: 0 solid rgba(0, 0, 0, 0); }
#banner .infos .details .title { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 30px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis; }
#banner .infos .details .title i { font-size: 15px; font-weight: normal; position: relative; top: -2px; padding-right: 1px; }
#banner .infos .details .presentation { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 18px; font-weight: 300; overflow: hidden; white-space: nowrap;text-overflow: ellipsis; }
#banner .infos .details .presentation strong { font-weight: 700; } /* gras avec Roboto */
#banner .slide { position: relative; z-index: 1; }
#banner .slide .cycle-slideshow span {font-size: 12px;  position: absolute; bottom: 20px; left: 20px; padding: 5px; color: #fff; -webkit-border-radius: 3px; border-radius: 3px;background: rgba(0,0,0,.5); }
#banner .slide img {z-index: 1; width: 100%; }
#banner .slide .cycle-prev {left: 0; background: url(../img/arrow2.png) no-repeat center center; }
#banner .slide .cycle-next {right: 0; background: url(../img/arrow3.png) no-repeat center center; }
#banner .slide .commandes a {position: absolute; z-index: 200; bottom: 40%; display: block; width: 48px; height: 65px; text-indent: -90000px; background-color: #7f7f7f; background-color: rgba(0,0,0,.6); }
#banner .slide .commandes a:hover { background-color: #28acf0; }
#banner .slide .pictos {font-size: 24px; position: absolute; z-index: 200; top: 15px; left: 0; }
#banner .slide .pictos a {line-height: 40px;  display: block; width: 40px; height: 40px; margin-bottom: 1px; text-align: center; text-decoration: none;color: #a2b0bf; background: #fff; }
#banner .slide .pictos a.active { color: #253746; }
#banner .slide .pictos a:hover { color: #456280; }
#banner .slide-pager { display: -webkit-flex; display: -ms-flexbox; display: flex; }
#banner .slide-pager .thumb { min-width:0; cursor: pointer; background: #eee; -webkit-flex: 1;  -ms-flex: 1;  flex: 1; }
#banner .slide-pager .thumb img { width: 100%; -webkit-filter:grayscale(0.3); filter:grayscale(0.3); }
#banner .slide-pager .thumb p { max-width: 100%; font-size: 16px; overflow: hidden; padding: 10px; white-space: nowrap; text-overflow: ellipsis; color: inherit; background: inherit; }
#banner .slide-pager .thumb.cycle-pager-active,
#banner .slide-pager .thumb:hover {color: #fff; border-color: #28acf0; background: #28acf0; }
#banner .slide-pager .thumb.cycle-pager-active img,
#banner .slide-pager .thumb:hover img { -webkit-filter:grayscale(0) saturate(2);; filter:grayscale(0) saturate(2); }

#mosaic { display: flex; flex-flow: row wrap; justify-content: space-between; align-items: stretch; }
#mosaic > * { width: 50%; }
#mosaic > * a { display: flex; flex-flow: row wrap; justify-content: space-between; align-items: stretch; text-decoration: none; }
#mosaic > * a > span { width: 50%; margin: auto; position: relative; border-color:inherit; }
#mosaic .item_details { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; text-align: center; padding: 0 10px;}
#mosaic .item_details span { font-size: 16px;  display: block; overflow: hidden; white-space: nowrap; -ms-text-overflow: ellipsis; text-overflow: ellipsis; }
#mosaic .item_details strong { font-weight: normal; font-size: 24px; display: block; overflow: hidden; white-space: nowrap; -ms-text-overflow: ellipsis; text-overflow: ellipsis; }
#mosaic > * a > span:last-child:before { content:"\2000"; position: absolute; top: 50%; display: block; height: 0; width: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; margin-top: -5px; }
#mosaic > *:nth-child(4n+3) a > span:first-child { order: 2; }
#mosaic > *:nth-child(4n+3) a > span:last-child { order: 1; }
#mosaic > *:nth-child(4n+4) a > span:first-child { order: 2; }
#mosaic > *:nth-child(4n+4) a > span:last-child { order: 1; }
#mosaic > *:nth-child(4n+1) a > span:last-child:before { left: -10px; border-right:10px solid; border-right-color:inherit; }
#mosaic > *:nth-child(4n+2) a > span:last-child:before { left: -10px; border-right:10px solid; border-right-color:inherit; }
#mosaic > *:nth-child(4n+3) a > span:last-child:before { right: -10px; border-left:10px solid; border-left-color:inherit; }
#mosaic > *:nth-child(4n+4) a > span:last-child:before { right: -10px; border-left:10px solid; border-left-color:inherit; }
#mosaic > *:nth-child(8n+1) a { background-color: #FFF    ; color:#333 ; border-color: #FFF    ; }
#mosaic > *:nth-child(8n+2) a { background-color: #29acf0 ; color:#fff ; border-color: #29acf0 ; }
#mosaic > *:nth-child(8n+3) a { background-color: #29acf0 ; color:#fff ; border-color: #29acf0 ; }
#mosaic > *:nth-child(8n+4) a { background-color: #c69c6a ; color:#fff ; border-color: #c69c6a ; }
#mosaic > *:nth-child(8n+5) a { background-color: #FFF    ; color:#333 ; border-color: #FFF    ; }
#mosaic > *:nth-child(8n+6) a { background-color: #FFF    ; color:#333 ; border-color: #FFF    ; }
#mosaic > *:nth-child(8n+7) a { background-color: #c69c6a ; color:#fff ; border-color: #c69c6a ; }
#mosaic > *:nth-child(8n+8) a { background-color: #29acf0 ; color:#fff ; border-color: #29acf0 ; }
#mosaic > * a .item_image { overflow: hidden; }
#mosaic > * a img { -webkit-filter:grayscale(0.3); filter:grayscale(0.3); }
#mosaic > * a:hover img { -webkit-filter:grayscale(0) saturate(2);; filter:grayscale(0) saturate(2); }
#mosaic > * a:hover { background-color: #c0392b ; color:#fff ; border-color: #c0392b ; }

#etb_banner {font-size: 0;  position: relative; z-index: 3;clear: both; }
#etb_banner .infos {font-size: 14px;position: relative; z-index: 2; bottom: -15px; left: -20px; display: inline-block; margin-top: -15px; padding: 0 0 0 38px; color: #fff;  background: #253746; }
#etb_banner .infos .details { padding: 10px 50px 12px 0; }
#etb_banner .infos .details:before {position: absolute; top: -20px; left: 0; width: 0;height: 0; content: '.'; text-indent: -8000px; border-width: 0 0 20px 20px; border-style: solid;  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #7d7f83; border-right: 0 solid rgba(0, 0, 0, 0); }
#etb_banner .infos .details h1 { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 30px; }
#etb_banner .infos .details h1 i { font-size: 15px; font-weight: normal; position: relative; top: -2px; padding-right: 1px; }
#etb_banner .infos .details .lieu { margin: -5px 0 5px 0; }
#etb_banner .infos .details .presentation { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 18px; font-weight: 300; }
#etb_banner .infos .details .presentation strong { font-weight: 700; } /* gras avec Roboto */
#etb_banner .infos .prix {font-size: 10px; padding: 0 5px;text-align: center;  background: #456280 url(../img/arrow1.png) no-repeat center bottom; }
#etb_banner .infos .prix strong { font-size: 30px; font-weight: 900; white-space: nowrap; }
#etb_banner .slide { position: relative; z-index: 1; }
#etb_banner .slide .cycle-slideshow span {font-size: 12px;  position: absolute; bottom: 20px; left: 20px; padding: 5px; color: #fff; -webkit-border-radius: 3px; border-radius: 3px;background: rgba(0,0,0,.5); }
#etb_banner .slide img {z-index: 1; width: 100%; }
#etb_banner .slide .selection {position: absolute; z-index: 200; top: -6px; right: -5px; width: 129px; height: 129px; text-indent: -90000px; background: url(../img/pastille-selection.png) no-repeat left top; }
#etb_banner .slide .cycle-prev {left: 0; background: url(../img/arrow2.png) no-repeat center center; }
#etb_banner .slide .cycle-next {right: 0; background: url(../img/arrow3.png) no-repeat center center; }
#etb_banner .slide .commandes a {position: absolute; z-index: 200; bottom: 40%; display: block; width: 48px; height: 65px; text-indent: -90000px; background-color: #7f7f7f; background-color: rgba(0,0,0,.6); }
#etb_banner .slide .commandes a:hover { background-color: #28acf0; }
#etb_banner .slide .pictos {font-size: 24px; position: absolute; z-index: 200; top: 15px; left: 0; }
#etb_banner .slide .pictos a {line-height: 40px;  display: block; width: 40px; height: 40px; margin-bottom: 1px; text-align: center; text-decoration: none;color: #a2b0bf; background: #fff; }
#etb_banner .slide .pictos a.active { color: #253746; }
#etb_banner .slide .pictos a:hover { color: #456280; }

#content {position: relative; padding: 20px; text-align: justify; }
#content + #content { padding-top: 0; }
#content p { margin: .75em 0 .5em 0; }
#content h1 {font-family: 'Roboto Slab', 'Times New Roman', Times, serif;  font-size: 28px; font-weight: 600; line-height: 1em;margin: .75em 0 .25em 0; text-align: left; color: #333; }
#content h2 {font-family: 'Roboto Slab', 'Times New Roman', Times, serif;  font-size: 24px; font-weight: 300; line-height: 1em;margin: .75em 0 .25em 0; text-align: left; color: #333; }
#content h2 strong { font-size: 30px; font-weight: 700; }
#content h3 {font-family: 'Roboto Slab', 'Times New Roman', Times, serif;  font-size: 18px; line-height: 1em;margin: .75em 0 .25em 0; text-align: left; color: #333; }
#content ul { margin: .75em 0 .5em 0; }
#content ul li {margin-bottom: .5em; padding: 0 0 0 15px; text-align: left; background: url(../img/puce1.png) no-repeat left 6px; }

#content h1 + h2 { margin-top: -.3em; }
#content h1 + h3 { margin-top: -.3em; }
#content h2 + h3 { margin-top: -.3em; }

#content h1:first-child, #content h2:first-child, #content h3:first-child, #content p:first-child, #content ol:first-child, #content ul:first-child { margin-top: 0; }

#content .slideshow { position: relative; max-width: 100%; z-index:1; }
#content .slideshow .cycle-prev {left: 0; background: url(../img/arrow4.png) no-repeat center center; }
#content .slideshow .cycle-next {right: 0; background: url(../img/arrow5.png) no-repeat center center; }
#content .slideshow .commandes a {position: absolute; z-index: 200; bottom: 40%; display: block; width: 27px; height: 40px; text-indent: -90000px; background-color: rgba(100,100,100,.75); }
#content .slideshow .commandes a:hover { background-color: #28acf0; }
#content .slideshow .cycle-slideshow div span { position: absolute; bottom: 10px; left: 0; display: inline-block; overflow: hidden; max-width: 95%; padding: 10px; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; background: rgba(255,255,255,.9); }
#content .slideshow .cycle-slideshow div span .titre {font-family: 'Roboto Slab', 'Times New Roman', Times, serif;  font-size: 18px; font-weight: normal;  color: #00aeef; }
#content .slideshow .cycle-slideshow div span strong { font-weight: 800; }
#content .slideshow .item-video :before {font-family: fontAwesome; font-size: 80px; line-height: 100px; position: absolute; top: 50%; left: 50%;  display: block; width: 140px; height: 100px; margin: -50px 0 0 -70px;content: '\f16a'; text-align: center; opacity: .7; color: #fff; border-radius: 20%; background: #000;  }

#content .badge-title {font-size: 14px; position: relative; z-index: 2; bottom: -15px; left: -40px; display: inline-block; max-width: 100%;margin: -15px 0 20px; padding: 10px 50px 12px 38px; color: #fff;  background: #253746; }
#content .badge-title:before {position: absolute; top: -20px; left: 0; width: 0;height: 0; content: '.'; text-indent: -8000px; border-width: 0 0 20px 20px; border-style: solid;  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #7d7f83; border-right: 0 solid rgba(0, 0, 0, 0); }
#content .badge-title strong {font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 30px; font-weight: 400;  display: block; overflow: hidden; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; }
#content .badge-title span {font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 18px; font-weight: 300;  display: block; overflow: hidden; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; }
#content .badge-title em { font-weight: 700; font-style: normal; } /* gras avec Roboto */

#content .idea-list li {font-size: 16px;padding: 0;  background: none;  }
#content .idea-list li a {line-height: 24px; position: relative; display: inline-block; height: 24px; padding: 0 10px 0 30px; text-decoration: none;  }
#content .idea-list li a:before {font-family: 'FontAwesome'; line-height: 20px; position: absolute; top: 2px; left: 2px; display: inline-block; width: 20px; height: 20px; content: '\f0eb '; text-align: center; color: #fff; border-radius: 50%; background: #28acf0;  }
#content .idea-list li a:hover {color: #fff; border-radius: 10px; background: #28acf0;  }

#content ul.ariane {margin-bottom: .75em;padding: 5px;  background: #eee;  }
#content ul.ariane li { display: inline; padding: 0; padding-left: 5px;list-style: none; background: none;  }
#content ul.ariane li:before {font-family: 'FontAwesome';  display: inline; padding-right: 5px;content: '\f105 ';  }
#content ul.ariane li a { text-decoration: none; }

#content .left {float: left;  width: 48%; margin-bottom: 20px; }
#content .right {float: right;  width: 48%; margin-bottom: 20px; }

#content .main {float: left;  width: 65%; margin-bottom: 20px; }
#content .sidebar {float: right;  width: 28%; margin-bottom: 20px; text-align: left; }
#content .box {margin: .75em 0; padding: 1em 1em .5em;  background: #ecf0f1; }

#content .back_to_top { text-align: right; }
#content .back_to_top a { text-decoration: none; color: #f39c12; }

#content.fiche_etb .infos h3 {font-size: 24px; font-weight: 300; color: #00aeef; }
#content.fiche_etb .infos .contact i {font-size: 22px; line-height: 34px; display: block; width: 34px; height: 34px; margin-bottom: 10px;text-align: center; color: #fff; border-radius: 34px;  background: #535354; }
#content.fiche_etb .infos .contact a {font-size: 12px;  float: left; padding-right: 20px; text-align: center; text-decoration: none;text-transform: uppercase; color: #535354; }
#content.fiche_etb .infos .contact a:hover { color: #333; }
#content.fiche_etb .infos .contact a:hover i { background: #333; }
#content.fiche_etb .infos .map {position: relative; top: -15px;  float: right; max-width: 92px; margin-bottom: -15px; }
#content.fiche_etb .reseaux { display: table; margin-bottom: 20px; }
#content.fiche_etb .reseaux h3 {display: table-cell; width: 35%; margin-right: 15px; vertical-align: middle; }
#content.fiche_etb .reseaux .pictos { display: table-cell; vertical-align: middle; }
#content.fiche_etb .reseaux .pictos i {font-size: 21px; line-height: 38px;  width: 38px; height: 38px; margin-right: 10px;text-align: center; color: #fff; border-radius: 38px; }
#content.fiche_etb .reseaux .pictos i.fa-pinterest {font-size: 23px; background: #cd2129; }
#content.fiche_etb .reseaux .pictos i:hover { background: #333; }

#content.fiche_etb .tripadvisor-wrapper { margin: 10px auto 10px; }
#content.fiche_etb .tripadvisor-wrapper .cdsROW { width: auto !important; height: auto !important; }
#content.fiche_etb .tripadvisor-wrapper .cdsROWContainer { width: auto !important; }
#content.fiche_etb .tripadvisor-wrapper .cdsROWContainer .cdsComponent {line-height: 16px;  width: auto; max-width: none !important; padding: 0 5px !important; white-space: normal; }
#content.fiche_etb .tripadvisor-wrapper .cdsROWContainer .cdsComponent:first-child { padding-left: 10px !important; }
#content.fiche_etb .tripadvisor-wrapper .cdsROWContainer .cdsComponent:last-child { padding-right: 10px !important; }
#content.fiche_etb .tripadvisor-wrapper .cdsROWContainer .logo { border: none; }

#content.fiche_etb .bloc img {z-index: 1; width: 100%; }
#content.fiche_etb .bloc h3 { font-size: 18px; font-weight: 300; color: #333; }
#content.fiche_etb .bloc h3 strong { font-size: 24px; font-weight: 600; }

#content.fiche_etb .ventes { clear: both; }
#content.fiche_etb .ventes img { max-width: 444px; }

#content.fiche_etb .ventes .picture { position: relative; }
#content.fiche_etb .ventes .picture .table { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
#content.fiche_etb .ventes .picture .table table { height: 100%; }
#content.fiche_etb .ventes .picture .col-left { width: 30%; text-align: center; vertical-align: middle;color: #456280; background: rgba(255,255,255,.75); }
#content.fiche_etb .ventes .picture .col-left .ico { margin: 15px 0; }
#content.fiche_etb .ventes .picture .col-left .jours i {display: block; width: 38px; height: 38px; margin: auto; background: url(../img/ico-jours-2x.png) no-repeat left top; background-size: cover; }
#content.fiche_etb .ventes .picture .col-left .vente i {font-weight: bold; font-style: normal;line-height: 38px; display: block; width: 38px; height: 38px; margin: auto; color: #fff; border-radius: 38px;  background: #456280; }
#content.fiche_etb .ventes .picture .col-left .qualite i {display: block; width: 38px; height: 38px; margin: auto; background: url(../img/ico-qualite-2x.png) no-repeat left top; background-size: cover; }
#content.fiche_etb .ventes .picture .col-right { width: 70%; text-align: center; vertical-align: bottom; }
#content.fiche_etb .ventes .picture .lien {font-size: 18px; font-weight: bold; display: inline-block; margin: 0 auto 15px auto; padding: 10px; text-decoration: none; color: #fff; background: #456280; }
#content.fiche_etb .ventes .picture .lien:hover { background: #28adf0; }

#content.fiche_etb .offres img { max-width: 410px; }
#content.fiche_etb .offres .cycle-slideshow { position: relative; }
#content.fiche_etb .offres .cycle-slideshow div span { position: absolute; bottom: 10px; left: 0; display: inline-block; overflow: hidden; max-width: 95%; padding: 10px; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; background: rgba(255,255,255,.9); }
#content.fiche_etb .offres .cycle-slideshow div span .titre {font-family: 'Roboto Slab', 'Times New Roman', Times, serif;  font-size: 18px; font-weight: normal;  color: #00aeef; }
#content.fiche_etb .offres .cycle-slideshow div span strong { font-weight: 800; }

#content.fiche_etb .offres .slide2 { position: relative; max-width: 410px; }
#content.fiche_etb .offres .cycle-prev {left: -27px; background: url(../img/arrow4.png) no-repeat center center; }
#content.fiche_etb .offres .cycle-next {right: -27px; background: url(../img/arrow5.png) no-repeat center center; }
#content.fiche_etb .offres .commandes a {position: absolute; z-index: 200; bottom: 40%; display: block; width: 27px; height: 40px; text-indent: -90000px; background-color: #7f7f7f; }
#content.fiche_etb .offres .commandes a:hover { background-color: #28acf0; }

footer#pied {font-size: 13px; line-height: 1.5em;color: #fff;  background: #253746; }
footer#pied a {text-decoration: none; color: #fff; }
footer#pied a:hover {text-decoration: none; color: #28adf0; }
footer#pied .copyright {font-size: 12px; padding: 10px;text-align: center;  color: #677078; }
footer#pied .copyright a { color: #677078; }
footer#pied .copyright a:hover { color: #fff; }
footer#pied .content { max-width: 1000px; margin: auto; padding: 30px 0 10px 10px; }
footer#pied .content .bloc { float: left; margin: 0 1%; }
footer#pied .content .newsletters { width: 21%; }
footer#pied .content .reseaux { width: 15%; }
footer#pied .content .plan { width: 36%; }
footer#pied .content .plan .left { float: left; width: 45%; }
footer#pied .content .partenaires { width: 19%; }
footer#pied .content .titre { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 20px;font-weight: 300; }

footer#pied p { margin: 0 0 25px 0; }
footer#pied .reseaux .pictos { margin: 0 0 10px 0; }
footer#pied .reseaux .pictos i {font-size: 17px;line-height: 25px;  width: 25px; height: 25px; margin: 0 10px 0 0; text-align: center; text-decoration: none; color: #253746; border-radius: 25px; background: #fff; background: #fff !important; }
footer#pied .reseaux .pictos a:hover i { background: #28adf0 !important; }

/** Modal **/

.modal.hide { display: none; }
.modal {position: fixed; z-index: 1000; top: 0; left: 0; width: 100%; height: 100%; background: #000; background: rgba(0,0,0,.7); }
.modal-wrapper {width: 600px; max-width: 90%; margin: 100px auto; padding: 20px; -webkit-border-radius: 10px; border-radius: 10px;  background: #fff; }
.modal-header {font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 20px; font-weight: bold;  position: relative; margin-bottom: 20px; border-bottom: 1px solid #999; }
.modal-header .close { position: absolute; top: 0;right: 0; }


/** Reseau **/

.fa-facebook { background: #3f5e9f; }
.fa-twitter { background: #00acd5; }
.fa-google-plus { background: #d84633; }



/** Styles IE8 **/
.ie678 #etb_banner .infos .details:before { border: none; }
.ie678 #content .ventes .picture .col-left { font-size: 13px; background: url(../img/bg-white.png) repeat left top; }
.ie678 #content .ventes .picture .col-left .jours i { background: url(../img/ico-jours.png) no-repeat left top; }
.ie678 #content .ventes .picture .col-left .qualite i { background: url(../img/ico-qualite.png) no-repeat left top; }
.ie678 #menu ul li { font-size: 11px; }
.ie67 #menu ul li {line-height: 40px; float: left; height: 40px; }
.ie678 #menu ul li strong { font-size: 12px; }
.ie678 #menu ul:after {line-height: 0; display: block; visibility: hidden; clear: both; height: 0;  content: '.'; }
.ie67 #menu ul li.reseaux { line-height: 15px; background: #ecf0f1; }
.ie67 #menu ul li.newsletter { line-height: 15px; background: #ecf0f1; }

/** Moteur de rechreche **/

#content .search-results section .section-name {font-size: 20px; font-weight: 400; text-transform: uppercase;  color: #28acf0; }
#content .search-results section .items {margin: 10px 0 30px; box-shadow: 1px 1px 10px #ccc; }

#content .search-results section .section-more {margin-bottom: 20px; text-align: center;  }

#content .search-results article {position: relative; display: flex; padding: 20px; border: 1px solid #f6f6f6;  background: #fff; }
#content .search-results article a { text-decoration: none; }
#content .search-results article a:first-child { flex: 1; }
#content .search-results article a:last-child {padding-right: 150px; flex: 2; }
#content .search-results article .picture { width: 95%; height: 150px; background-repeat: no-repeat; background-position: center center; -webkit-background-size: cover; -o-background-size: cover; background-size: cover; }
#content .search-results article .item-name {margin: 0; color: #28acf0; }
#content .search-results article .item-location {font-size: 1.2em; font-style: italic; margin: .2em 0;  color: #d35400; }
#content .search-results article .item-location:before { font-family: 'FontAwesome'; font-style: normal;padding-right: 5px; content: '\f041';  }
#content .search-results article .item-type {font-size: 1.1em;margin: 0;  color: #333; }
#content .search-results article .item-resume { color: #333; }

#content .search-results article .item-selection { font-size: .9em; position: absolute; left: -15px; bottom: 30px; padding: 10px; text-transform: uppercase;color: #fff; background: #28acf0;  }
#content .search-results article .item-selection:after { font-family: 'FontAwesome'; font-style: normal;padding-left: 10px; content: '\f219 ';  }
#content .search-results article .item-selection:before {position: absolute; top: -15px;left: 0; display: block; width: 0; height: 0;  content: '\2000'; border-top: 15px solid transparent; border-right: 15px solid #aaa;  }

#content .search-results article .item-more { font-size: .9em; position: absolute; right: -15px; bottom: 20px; padding: 10px; text-transform: uppercase;color: #fff; background: #253746;  }
#content .search-results article .item-more:after { font-family: 'FontAwesome'; font-style: normal;padding-left: 10px; content: '\f105 ';  }
#content .search-results article .item-more:before {position: absolute; top: -15px;right: 0; display: block; width: 0; height: 0;  content: '\2000'; border-top: 15px solid transparent; border-left: 15px solid #aaa;  }

#content .search-results article:hover { background: #f6f6f6; }
#content .search-results article:hover .item-more { background: #28acf0; }

#content .search {font-family: 'Roboto Slab', 'Times New Roman', Times, serif; margin: 0 -20px 40px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; background: #f6f6f6;  }
#content .search .search-header {display: inline-block; margin: -40px 0 0 -20px; padding: 20px 20px 20px 40px; color: #fff;  background: #253746; }
#content .search .search-header .search-title { font-size: 1.8em; font-weight: 700; }
#content .search .search-header .select { position: relative; margin-right: 10px; }
#content .search .search-header .select > select { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 1.1em; font-weight: 300; padding: 4px 30px 4px 5px;color: #fff; border: 1px solid #fff; background: #253746; -webkit-appearance: none; -moz-appearance: none; appearance: none;  }
#content .search .search-header .select:after {font-family: 'FontAwesome';  position: absolute; top: 0;right: 10px; display: block; content: '\f107'; color: #fff;  }
#content .search .search-header button { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 1.1em; padding: 6px 20px;border: none; background: #fff; }
#content .search .search-header button:hover {color: #fff;  background: #29acf0; }

#content .search .search-body {font-size: 1.2em;  padding: 20px; color: #333; }
#content .search .search-body:after { display: block; visibility: hidden;clear: both; height: 0; content: '\2000';  }
#content .search .search-body .search-filter { float: left; height: 40px; }
#content .search .search-body .search-filter dt { font-weight: 700; display: inline; }
#content .search .search-body .search-filter dd {font-weight: 400;  display: inline; }
#content .search .search-body .search-filter input { display: none; }

#content .search .search-body .search-filter input + span:before { font-family: 'FontAwesome'; margin: 0 5px 0 10px;content: '\f096'; }
#content .search .search-body .search-filter input:checked + span:before { content: '\f14a'; }
#content .search .search-body .search-mode {position: relative; top: -6px; float: right; }
#content .search .search-body .search-mode dt { font-weight: bold; display: inline; }
#content .search .search-body .search-mode dd { display: inline; padding: 0; }
#content .search .search-body .search-mode dd span { display: inline-block; margin: 0; padding: 5px 10px; border: 1px solid #253746; }
#content .search .search-body .search-mode dd:last-child span { border-left: none; }
#content .search .search-body .search-mode dd[data-mode=list] span:before { font-family: 'FontAwesome'; margin-right: 10px; content: '\f00b'; }
#content .search .search-body .search-mode dd[data-mode=map] span:before { font-family: 'FontAwesome'; margin-right: 10px; content: '\f041'; }
#content .search .search-body .search-mode input { display: none; }
#content .search .search-body .search-mode input:checked + span {color: #fff;  background: #253746; }


/* FICHE GUIDE */

#content.fiche_guide .guide_etape .titre {margin-top: 2em; color: #28acf0; }
#content.fiche_guide .guide_etape blockquote {margin: 0; padding: 2em 0 0 1em;  border-left: 2px dotted #eee; }
#content.fiche_guide .slideshow { margin-top: 1.5em; }

#content.fiche_guide .summary .title { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 20px; font-weight: 300;text-transform: uppercase; color: #28acf0; }
#content.fiche_guide .summary ol { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; counter-reset: numlist_counter; /* on initialise et nomme un compteur */ box-shadow: 0 0 5px rgba(100,100,100,.5); }
#content.fiche_guide .summary ol a { text-decoration: none; }
#content.fiche_guide .summary ol > li { list-style-type: none; counter-increment: numlist_counter; /* on incrémente le compteur à chaque nouveau li */ border-bottom: 1px solid #eee; }
#content.fiche_guide .summary ol > li > a {display: block; overflow: hidden; padding: 6px 6px; white-space: nowrap; text-overflow: ellipsis;-ms-text-overflow: ellipsis;  border-left: 5px solid transparent;  }
#content.fiche_guide .summary ol > li > a:before {padding-right: 5px; content: counter(numlist_counter, upper-roman)'.';  }
#content.fiche_guide .summary ol > li > a:hover {color: #28acf0; border-left-color: #28acf0;  }

#content.fiche_guide .hebergement { margin-top: 1.5em; }
#content.fiche_guide .hebergement .title { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 20px; font-weight: 300; text-transform: uppercase; color: #28acf0; }
#content.fiche_guide .hebergement .encart {display: block; margin-top: 1.5em; padding: 0; text-decoration: none; box-shadow: 0 0 5px rgba(100,100,100,.7);  }
#content.fiche_guide .hebergement .encart .title {font-size: 14px; font-weight: 600; display: block; overflow: hidden;  padding: 10px 5px 0; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; color: #28acf0; border-left: 5px solid transparent; }
#content.fiche_guide .hebergement .encart .location {font-size: 14px; display: block; overflow: hidden;  padding: 0 5px 10px; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; color: #d35400; border-left: 5px solid transparent; }
#content.fiche_guide .hebergement .encart .location:before { font-family: 'FontAwesome'; font-style: normal; padding-right: 5px; content: '\f041'; }
#content.fiche_guide .hebergement .encart:hover .title, #content.fiche_guide .hebergement .encart:hover .location { border-color: #28acf0; }


/* FICHE GUIDE */

#content.fiche_place .place_description h2 { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; color: #28acf0; }
#content.fiche_place .place_description h3 { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; color: #28acf0; }
#content.fiche_place .place_description .title { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; color: #28acf0; }

#content.fiche_place .hebergement { margin-top: 1.5em; }
#content.fiche_place .hebergement .title { font-family: 'Roboto Slab', 'Times New Roman', Times, serif; font-size: 20px; font-weight: 300; text-transform: uppercase; color: #28acf0; }
#content.fiche_place .hebergement .encart {display: block; margin-top: 1.5em; padding: 0; text-decoration: none; box-shadow: 0 0 5px rgba(100,100,100,.7);  }
#content.fiche_place .hebergement .encart .title {font-size: 14px; font-weight: 600; display: block; overflow: hidden;  padding: 10px 5px 0; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; color: #28acf0; border-left: 5px solid transparent; }
#content.fiche_place .hebergement .encart .location {font-size: 14px; display: block; overflow: hidden;  padding: 0 5px 10px; white-space: nowrap; text-overflow: ellipsis; -ms-text-overflow: ellipsis; color: #d35400; border-left: 5px solid transparent; }
#content.fiche_place .hebergement .encart .location:before { font-family: 'FontAwesome'; font-style: normal; padding-right: 5px; content: '\f041'; }
#content.fiche_place .hebergement .encart:hover .title, #content.fiche_guide .hebergement .encart:hover .location { border-color: #28acf0; }

/* FORMULAIRE GENERIQUE */

form .input-group {margin-bottom: 5px;  padding: 3px; border: 1px solid transparent; }
form .input-group label {line-height: 1em;  display: block; padding: 5px; }
form .input-group > input {font-size: 14px;line-height: 30px;  width: 100%; height: 30px; padding: 5px;  }
form .input-group > textarea {font-size: 14px;line-height: 30px;  width: 100%; min-height: 160px; padding: 5px;  }

form .input-group.input-checkbox, form .input-group.input-radio { padding: 0; }
form .input-group.input-checkbox label, form .input-group.input-radio label {line-height: 1.25em; position: relative;  display: block; margin-bottom: 5px; padding-left: 24px; border: 1px solid transparent; }
form .input-group.input-checkbox label input, form .input-group.input-radio label input { position: absolute; left: 0; }

form .input-group.input-error { border: 1px solid #c0392b; }

form .input-group.input-checkbox label.input-error, form .input-group.input-checkbox label.input-error { border: 1px solid #c0392b; }

/* POPIN */
.background-popin{display: none; position: absolute; width: 100%; height: 100%; background-color: rgba(12, 12, 12, 0.78);; z-index: 5;}
#popin{display: none; position: absolute; text-align: center; width: 100%;}
#popin a{text-decoration: none; border: 0;}
#popin .close-popin{position: absolute; right:0; top:0; height: 30px; width: 45px; background-color: #EA7201; cursor: pointer;}
#popin .img-popin{position: relative; height: 440px; width: 650px; margin: auto; z-index: 6;}
#popin .fa{color: white; font-size: 19px; margin-top: 5px;}